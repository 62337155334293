import { IoTimeOutline } from "react-icons/io5";
import { IoHeartOutline } from "react-icons/io5";
import { ImCoinEuro } from "react-icons/im";
import logo from '../images/logo.PNG';
import { useToasts } from 'react-toast-notifications';
import { useContext, useEffect } from 'react';
//import { ContactForm } from '../pages/Unused/ContactForm';
export default function Home() {
  const { addToast } = useToasts();
  useEffect(() => {
    addToast("Deze site gebruikt cookies. Door gebruik te maken van deze website ga je akkoord met het gebruik van cookies.", {
      appearance: 'warning',
      autoDismiss: true,
    })
  }, []);


  return (
    <>
      <div className="mt-10">
        <h1 tabIndex="0" className="text-center text-black font-bold text-4xl pt-8">Shuttle Service Billiet</h1>
        <p className="text-lg pt-5 pb-5 p-2" tabIndex="0"><strong>Het team van SSB dankt zijn klanten voor 25 jaar vertrouwen.</strong></p>
        <p className="text-lg pt-5 pb-5 p-2" tabIndex="0">Het werd tijd om nieuwe wegen in te slaan...</p>
        <p className="text-lg pt-5 pb-5 p-2" tabIndex="0"></p>
        <p className="text-lg pt-5 pb-5 p-2" tabIndex="0">Karel Billiet</p>
      </div>

    </>
  );

}
