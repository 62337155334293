import logo from '../images/logo.PNG';

export default function LogoHeader() {

	return (
    <>		
      <div className="grid justify-items-center">
        <img src={logo} alt="logo" className="w-44 pt-4"/>
      </div>
    </>
	);
};