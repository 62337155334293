import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import { BrowserRouter } from "react-router-dom";
import { HashRouter} from "react-router-dom";
import {ToastProvider} from 'react-toast-notifications';

/*
ReactDOM.render(
  <React.StrictMode>    
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
*/

// https://www.freecodecamp.org/news/deploy-a-react-app-to-github-pages/
ReactDOM.render(
  <React.StrictMode> 
    <ToastProvider placement='bottom-left' autoDismissTimeout='7000'>  
    <HashRouter>
      <App />
    </HashRouter>
    </ToastProvider> 
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
