import './App.css';
import {Routes,Route} from "react-router-dom";
import Home  from './pages/Home';
import { ContactForm } from './pages/Unused/ContactForm';
import { NotFound } from './pages/NotFound';
import { EmailProvider } from "./contexts/EmailProvider";
import NavigatieBalk from './pages/NavigatieBalk';
import LogoHeader from './components/LogoHeader';
import logo from './images/logo.PNG';
import {
  useTheme,
} from './contexts/AccessibilityImprovements/Theme.context';


function App() {


  return (
    <EmailProvider>
      <div className="App flex flex-col h-screen" >
        <div className="flex-grow ">
          <Routes>
            <Route path="*" element={<LogoHeader />} />
          </Routes>
        
          <Routes>
            <Route exact path="/" element={<Home />} />
            {/* <Route path="contactForm" element={<ContactForm />} /> */}
            <Route path="*" element = {<NotFound/>}/>
          </Routes>
        </div>
        <footer class="bg-white rounded-lg   m-4">
        <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">

            <hr class="my-6 sm:mx-auto  lg:my-8" />
            <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400" tabIndex="0">© 2024 - Shuttle Service Billiet - BE 0501 702 806</span>
        </div>
    </footer>
      </div>
    </EmailProvider>
  );
}

export default App;
