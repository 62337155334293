import pagenotfound from '../images/pagenotfound.jpg';

export const NotFound = () => {
  return (
    <>
      <div className="grid grid-cols-2 xl:grid-cols-1">
        <div><img src={pagenotfound} alt="" className="p-20"/></div>
        <div className="p-20 m-20">
          <p className="font-bold text-xl">Awww...Don't Cry.</p>
          <p className="text-yellow-500 font-bold">It's just a 404 Error!</p>
          <p>With us you will arrive at your destination stress-free and with class.</p>
          <p>Try to consult another page.</p>
        </div>
      </div>
      
  </>
  );
};