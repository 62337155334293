import {
  axios
} from '.';


export const sendEmail = async ({
  to,subject, text
  
}) => {
  const {
    data
  } = await axios({
    method: 'post',
    url: `https://ansofie-ssb.dnsalias.net/api/email/`,
    data: {
     to, subject, text
    },
  });
  
  return data;
};

