import {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo
} from 'react';

import * as emailApi from '../api/email';
export const EmailContext = createContext();
export const useEmail = () => useContext(EmailContext);

export const EmailProvider = ({
  children
}) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const sendMail= useCallback(async ({
    to,subject, text
  }) => {
    setError();
    setLoading(true);
    try {
      const antwoord = await emailApi.sendEmail({
        to,subject, text
      });

      return antwoord;
    } catch (error) {

      if ((error.hasOwnProperty("isAxiosError")) && (error.isAxiosError === true))
      {
        let message = "";
        if (error.response.data.code ==='INTERNAL_SERVER_ERROR')
        {

          message = error.response.data.message;
        }
        else
        {
          if (error.response.data.code === 'VALIDATION_FAILED')
          {
            const foutBericht = error.response.data.details.body;
        
            for (const  value of Object.values(foutBericht)) {
                for (const [key2, value2] of Object.entries(value[0])) {
                    if(key2 === "message"){
                        message = value2;
                    }
                }
            }
          }
        }
        setError(message);
        throw message;
      }
      throw error;
    } finally {
      setLoading(false)
    }
  }, []);
  const value = useMemo(
    () => ({
      sendMail,
      error,
      loading,
      
    }),
    [
      sendMail,
      error,
      loading,
      
    ]
  );
  return (
    <EmailContext.Provider value={value}>
      {children}
    </EmailContext.Provider>
  );
 };